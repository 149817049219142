<template>
  <b-card>
    <form-component :tag="tag" @onSubmit="onSubmit" @fetchByLocale="fetchTag" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/downloadable-files-tags/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/downloadable-files-tags';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      tag: {
        name: '',
      },
    };
  },

  async mounted() {
    await this.fetchTag();
  },

  methods: {
    async fetchTag(lang = 'pl') {
      try {
        const { data } = await show(this.$route.params.id, { lang });

        this.tag = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania tagu. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async onSubmit(form) {
      try {
        await update(this.tag.id, form);

        this.showSuccessNotification('Dane zapisane', 'Tag został zaktualizowany.');
        this.$router.push({ name: 'downloadable-files-tags-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił błąd podczas edycji tagu. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
